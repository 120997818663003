import { Component, Inject } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { BehaviorSubject, combineLatest, of, shareReplay } from "rxjs"
import { exhaustMap, map, mapTo, switchMap, take } from "rxjs/operators"

import { Field, FileDownloadService, LoadFields, StaticSource, ToastService } from "@anzar/core"
import { DataSourceDirective } from "@anzar/core"
import { Entity } from "@anzar/rpc"

import { TaskService } from "@pyzar/task.module"

import { ComplaintType, DeliveryStatus, PartnerRepoSource, PaymentMethod } from "@backend/__anzar_rpc_output"
import { Order, OrderRepo, OrderRepoSource } from "@backend/order.api"

import { BACKEND_BASE_URL, PartnerService } from "../common.module"
import { OrderDetailsWndService } from "./details/order-details-wnd.component"
import { STATUS_COLORS } from "./status"
import { OrderWizardWndService } from "./wizard/order-wizard-wnd.component"

const ORDER_FIELDS: LoadFields<Order> = [
    "id",
    "status",
    "final_price_gross",
    "partner_entity_id",
    "partner_orderno",
    "created_time",
    "updated_time",
    "cancellation_request",
    "currency",
    "payment_status",
    "proforma_invoice_id",
    "pending_todo",
    "delivery_status",
    { partner: ["name"] },
    { shipping_info: ["name"] },
    { billing_info: ["payment_method", "proforma_need"] },
    {
        items: [
            "qty_ordered",
            "qty_cancelled",
            "qty_purchased",
            "qty_pending",
            "qty_received",
            "qty_backorder",
            "supply_error",
            "supplier_ids",
            "supplier_available_ids",
            "supply_type"
        ]
    }
]

class SelectEntry extends Entity {
    @Field({ primary: true }) public value: string
    @Field() public label: string
}

const ComplaintStatusSrc = new StaticSource(SelectEntry, [
    { value: "UNTOUCHED", label: "Érintetlen" },
    { value: "PROGRESS", label: "Folyamatban" },
    { value: "CLOSED", label: "Lezárt" }
])

const ProformaStatusSrc = new StaticSource(SelectEntry, [
    { value: "MISSING", label: "Hiányzik" },
    { value: "PENDING", label: "Folyamatban" },
    { value: "PAID", label: "Fizetve" }
])

const SUPPLY_INFO_CACHE = Symbol("SUPPLY_INFO_CACHE")

@Component({
    selector: ".eur-order-grid",
    templateUrl: "./order-grid.component.pug",
    providers: [
        {
            provide: DataSourceDirective,
            deps: [OrderRepoSource],
            useFactory: function (source: OrderRepoSource) {
                const result = new DataSourceDirective()
                result.dataSource = source
                return result
            }
        },
        OrderDetailsWndService,
        OrderWizardWndService
    ]
})
export class OrderGridComponent {
    public readonly fields = ORDER_FIELDS
    public readonly statusColors = STATUS_COLORS

    public readonly deliveryStatusSrc = DeliveryStatus.DATA
    public readonly complaintTypeSrc = ComplaintType.DATA
    public readonly paymentMethodSrc = PaymentMethod.DATA
    public readonly complaintStatusSrc = ComplaintStatusSrc
    public readonly proformaStatusSrc = ProformaStatusSrc

    public readonly FF_ALL = {}
    public readonly FF_DRAFT = { status: "DRAFT" }
    public readonly FF_PENDING = { pending: true }
    public readonly FF_TODO = { pending_todo: { gt: 0 } }
    public readonly FF_PROGRESS = { status: "PROGRESS" }
    public readonly FF_WORKABLE = { workable: true }
    public readonly FF_PREPARED = { has_prepared_shipping: true }
    public readonly FF_COMPLETED = { status: { in: ["COMPLETED"] } }
    public readonly FF_CANCELLED = { status: "CANCELLED" }
    public readonly FF_COMPLAINTS = { or: [{ qty_complaint: { gt: 0 } }, { status: "RETURNED" }] }

    // public fastFilter: any = this.FF_ALL
    // public supplyTypeFilter: any = null

    public readonly fastFilter$ = new BehaviorSubject<any>(this.FF_ALL)
    public readonly supplyTypeFilter$ = new BehaviorSubject<any>(null)
    public readonly advancedFilter$ = new BehaviorSubject<any>(null)

    public readonly gridFilter$ = combineLatest({
        fast: this.fastFilter$,
        supplyType: this.supplyTypeFilter$,
        advanced: this.advancedFilter$
    }).pipe(
        map(({ fast, supplyType, advanced }) => {
            return { ...fast, ...advanced, supply_type: supplyType }
        }),
        shareReplay(1)
    )

    // public get gridFilter() {
    //     return { ...this.fastFilter, supply_type: this.supplyTypeFilter }
    // }

    private readonly reloadTodoCounts = new BehaviorSubject<void>(undefined)

    public readonly todoCounts = this.reloadTodoCounts.pipe(
        switchMap(() => this.orderRepo.get_todo_counts()),
        shareReplay(1)
    )

    public readonly advancedFilter = new FormGroup({
        payment_method: new FormControl(),
        delivery_status: new FormControl(),
        supplier_id: new FormControl(),
        supplier_invoiceno: new FormControl(),
        complaint_type: new FormControl(),
        complaint_status: new FormControl(),
        proforma_status: new FormControl()
    })

    public constructor(
        @Inject(DataSourceDirective) public readonly dataSource: DataSourceDirective,
        @Inject(OrderDetailsWndService) private readonly detailsSvc: OrderDetailsWndService,
        @Inject(OrderWizardWndService) private readonly wizardWnd: OrderWizardWndService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(FileDownloadService) private readonly download: FileDownloadService,
        @Inject(TaskService) private readonly taskSvc: TaskService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {
        setTimeout(() => {
            // preload partners
            this.partnerSvc.partners$.pipe(take(1)).subscribe()
        }, 500)
    }

    public onRowTap(orderId: number) {
        this.detailsSvc
            .show(orderId)
            .pipe(
                switchMap(orderId => {
                    this.reloadTodoCounts.next()
                    if (orderId === -1) {
                        return of(this.dataSource.storage.reload())
                    } else {
                        return this.dataSource.storage.reloadItem({ pk: String(orderId) } as any)
                    }
                })
            )
            .subscribe()
    }

    public printShippingLabels() {}

    public invoiceAndShip() {
        let return_value: any
        this.taskSvc
            .start("order-invoice-shipping")
            .pipe(
                exhaustMap(event => {
                    if (event.type === "success") {
                        this.reloadTodoCounts.next()
                        return_value = event.data.return_value
                        return this._downloadShippingPackage(return_value.shipping_ids).pipe(mapTo(event))
                    } else if (event.type === "button") {
                        if (event.role === "download_pacakge") {
                            return this._downloadShippingPackage(return_value.shipping_ids).pipe(mapTo(event))
                        }
                    }
                    return of(event)
                })
            )
            .subscribe()
    }

    // private _downloadShippingExcel(shippingIds: number[]) {
    //     return this.download
    //         .download(`${this.baseUrl}/get/shippings?shipping_ids=${shippingIds.join(",")}`)
    //         .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállító excel letöltése" }))
    // }

    // private _downloadShippingLabels(shippingIds: number[]) {
    //     return this.download
    //         .download(`${this.baseUrl}/get/carrier-label.pdf?shipping_ids=${shippingIds.join(",")}`)
    //         .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállító cimkék előállítása" }))
    // }

    private _downloadShippingPackage(shippingIds: number[]) {
        return this.download
            .download(`${this.baseUrl}/get/shipping-package?shipping_ids=${shippingIds.join(",")}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállítási adatok letöltése" }))
    }

    public dollarColor(order: Order) {
        if (order.payment_status.value === "PAID") {
            return STATUS_COLORS["COMPLETED"]
        } else if (order.proforma_invoice_id) {
            return "critical"
        } else {
            return "warn"
        }
    }

    public itemSupply(order: Order): { [key: string]: any } {
        if ((order as any)[SUPPLY_INFO_CACHE] == null) {
            ;(order as any)[SUPPLY_INFO_CACHE] = this._itemSupply(order)
        }
        return (order as any)[SUPPLY_INFO_CACHE]
    }
    private _itemSupply(order: Order): { [key: string]: any } {
        let qty_ordered = 0
        let qty_purchased = 0
        let qty_pending = 0
        let qty_received = 0
        let qty_cancelled = 0
        let qty_backorder = 0
        let qty_preorder = 0
        const errors: string[] = []
        let missingSupplier: boolean = false
        let hasAlternateSupplier: boolean = false

        for (const item of order.items) {
            qty_ordered += item.qty_ordered
            qty_purchased += item.qty_purchased
            qty_received += item.qty_received
            qty_pending += item.qty_pending
            qty_cancelled += item.qty_cancelled

            if (item.supply_error) {
                errors.push(item.supply_error)
            }

            const saids = item.supplier_available_ids || []
            if (!item.supplier_ids || item.supplier_ids.length === 0) {
                missingSupplier = true
            } else {
                hasAlternateSupplier =
                    hasAlternateSupplier || saids.filter(v => !item.supplier_ids.includes(v)).length > 0
            }

            if (item.supply_type.value === "PREORDER") {
                qty_preorder += order.status.value === "DRAFT" ? item.qty_ordered : item.qty_backorder
            } else {
                qty_backorder += item.qty_backorder
            }
        }

        const qty_progress = qty_pending + qty_purchased - Math.max(0, qty_received - qty_cancelled)

        return {
            qty_ordered,
            qty_progress,
            qty_received,
            qty_backorder,
            qty_preorder,
            missingSupplier,
            hasAlternateSupplier,
            error: errors.join("\n")
        }
    }

    public export() {
        const filter = JSON.stringify(this.dataSource.filter)
        const sorter = JSON.stringify(this.dataSource.sort)
        this.download
            .download(`${this.baseUrl}/get/orders?filter=${encodeURI(filter)}&sorter=${encodeURI(sorter)}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Rendelések letöltése" }))
            .subscribe()
    }

    public toggleFilter(ff: any) {
        if (this.fastFilter$.value === ff) {
            this.fastFilter$.next({})
        } else {
            this.fastFilter$.next(ff)
        }
    }

    public doFilterSupplyType(st: string) {
        if (this.supplyTypeFilter$.value === st) {
            this.supplyTypeFilter$.next(null)
        } else {
            this.supplyTypeFilter$.next(st)
        }
    }

    public newOrder() {
        this.wizardWnd.show().subscribe(orderId => {
            this.fastFilter$.next(this.FF_DRAFT)
            this.dataSource.storage.reload()
            this.onRowTap(orderId as number)
        })
    }

    public doResetAdvancedFilter() {
        this.advancedFilter.reset()
        this.advancedFilter$.next(null)
    }

    public doApplyAdvancedFilter() {
        const values = this.advancedFilter.value
        const filter = {} as any

        if (values.payment_method) {
            filter["billing_info.payment_method"] = { in: values.payment_method }
        }

        if (Array.isArray(values.delivery_status)) {
            if (values.delivery_status.includes("PENDING")) {
                filter.delivery_status = { or: [{ in: values.delivery_status }, { eq: null }] }
            } else {
                filter.delivery_status = { in: values.delivery_status }
            }
        }

        if (values.supplier_id) {
            filter["sorders.partner_id"] = { in: values.supplier_id }
        }

        if (values.supplier_invoiceno) {
            filter.supplier_invoiceno = values.supplier_invoiceno
        }

        if (values.complaint_type) {
            filter["complaints.type"] = { in: values.complaint_type }
        }

        if (values.complaint_status) {
            filter["complaint_status"] = values.complaint_status
        }

        if (values.proforma_status) {
            filter["proforma_status"] = values.proforma_status
        }

        this.advancedFilter$.next(filter)
    }
}
