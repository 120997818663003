import { Component, Directive, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"

import { BehaviorSubject, combineLatest, map, shareReplay } from "rxjs"

import { DropdownLayer, LayerService, LoadFields } from "@anzar/core"

import { OrderExtraItem, Partner } from "@backend/__anzar_rpc_output"
import { Order, OrderItem } from "@backend/order.api"

import { convertPrice, marginOf, PartnerService } from "../../common.module"
import { ProductSheetService } from "../../product.module"
import {
    ItemChangeSupplierComponent,
    ItemChangeSupplierParams,
    ItemSupplyErrorComponent,
    ItemSupplyErrorParams,
    ItemUpdateQtyComponent,
    ItemUpdateQtyParams,
    PARAMS
} from "./item-action/item-action.component"
import { OrderDetailsService } from "./order-details.service"

export const ORDER_ITEM_FIELDS: LoadFields<OrderItem> = [
    "id",
    "product_id",
    "total_price",
    "unit_price",
    "supply_error",
    "supply_type",
    "line",
    "name",
    "qty_ordered",
    "qty_cancelled",
    "qty_refunded",
    "qty_purchased",
    "qty_pending",
    "qty_received",
    "qty_invoiced",
    "qty_shipped",
    "qty_backorder",
    "supplier_ids",
    {
        so_items: [
            "id",
            "qty",
            "qty_ordered",
            "qty_received",
            "unit_price",
            {
                order: ["is_cancelled", "is_created", "is_opened", "partner_id", { warehouse: ["name", "priority"] }]
            }
        ],
        reservations: [
            "qty",
            "is_processed",
            {
                warehouse: ["name", "partner_id"]
            }
        ]
    }
]

export const ORDER_EXTRA_ITEM_FIELDS: LoadFields<OrderExtraItem> = [
    "id",
    "name",
    "item_id",
    "unit_price",
    "total_price",
    "qty_ordered",
    "qty_cancelled",
    "qty_invoiced",
    "qty_refunded"
]

interface SupplyInfo {
    color:
        | "normal"
        | "critical"
        | "dark-warn"
        | "info"
        | "dark-info"
        | "common"
        | "dark-common"
        | "confirm"
        | "blue"
        | "vibrant"
    content: string
    price?: SPrice
    action?: SupplyInfoAction
}

type SupplyInfoAction =
    | { type: "update-qty"; params: ItemUpdateQtyParams }
    | { type: "update-supplier"; params: ItemChangeSupplierParams }
    | { type: "supply-error"; params: ItemSupplyErrorParams }

interface SPrice {
    prefix?: string
    amount: number
    currency: string
}

@Directive()
export abstract class OrderItemBase<T> {
    @Input() public order: Order
    @Input() public blockInteraction: boolean = false
    @Output() public readonly changes = new EventEmitter<void>()

    public name: string
    public productId?: number

    protected readonly _orderItem = new BehaviorSubject<T>(null)

    public readonly supplyInfo$ = combineLatest({
        item: this._orderItem,
        partners: this.partnerSvc.allPartners$
    }).pipe(
        map(({ item, partners }) => this._determineSupplyInfos(item, partners)),
        shareReplay(1)
    )

    public constructor(
        @Inject(ProductSheetService) protected readonly psheet: ProductSheetService,
        @Inject(LayerService) protected readonly layerSvc: LayerService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(OrderDetailsService) private readonly orderDetailsSvc: OrderDetailsService
    ) {}

    public showProduct() {
        if (this.productId != null) {
            this.psheet.show(this.productId).subscribe()
        }
    }

    public showSupplyAction(event: Event, info: SupplyInfo) {
        if (info.action == null || event.defaultPrevented || this.blockInteraction) {
            return
        }

        event.preventDefault()

        let component = null
        switch (info.action.type) {
            case "supply-error":
                component = ItemSupplyErrorComponent
                break

            case "update-qty":
                component = ItemUpdateQtyComponent
                break

            case "update-supplier":
                component = ItemChangeSupplierComponent
                break
        }

        if (component == null) {
            throw new Error(`Unexpected type: ${info.action.type}`)
        }

        const behavior = new DropdownLayer({
            menuLike: true,
            backdrop: { type: "empty", hideOnClick: true },
            rounded: 3,
            elevation: 10,
            trapFocus: true,
            position: {
                align: "top left",
                anchor: {
                    ref: event.target as HTMLElement,
                    align: "bottom left",
                    margin: 8
                }
            }
        })

        const layerRef = this.layerSvc.createFromComponent(component as any, behavior, null, [
            { provide: OrderDetailsService, useValue: this.orderDetailsSvc },
            { provide: PARAMS, useValue: info.action.params }
        ])
        layerRef.subscribe(event => {
            if (event.type === "done") {
                this.changes.next()
            }
        })

        layerRef.show()
    }

    protected abstract _determineSupplyInfos(item: T, partners: Partner[]): SupplyInfo[]
}

@Component({
    selector: ".eur-order-item[item]",
    templateUrl: "./item.component.pug"
})
export class OrderItemComponent extends OrderItemBase<OrderItem> implements OnChanges {
    @Input() public item: OrderItem

    public ngOnChanges(changes: SimpleChanges) {
        if ("item" in changes) {
            const item = changes.item.currentValue as OrderItem
            this.name = item.name
            this.productId = item.product_id
            this._orderItem.next(item)
        }
    }

    protected _determineSupplyInfos(item: OrderItem, partners: Partner[]): SupplyInfo[] {
        function getPartnerName(partnerId: number): string {
            const partner = partners.find(p => `${p.id}` === `${partnerId}`)
            return partner ? partner.name : ""
        }

        const result: SupplyInfo[] = []

        // Alpa adat, hogy mennyi lett rendelve
        result.push({
            color: "dark-common",
            content: `${item.qty_ordered} db megrendelve`,
            price: {
                amount: item.total_price.gross,
                currency: item.total_price.currency
            },
            action: {
                type: "update-qty",
                params: {
                    type: "ordered",
                    qty: item.qty_ordered - item.qty_cancelled,
                    orderId: this.order.id,
                    orderItemId: item.id
                }
            }
        })

        if (item.qty_cancelled && item.qty_cancelled !== item.qty_refunded) {
            result.push({
                color: "dark-warn",
                content: `${item.qty_cancelled} db lemondva`,
                action: {
                    type: "update-qty",
                    params: {
                        type: "cancelled",
                        qty: item.qty_cancelled,
                        orderId: this.order.id,
                        orderItemId: item.id
                    }
                }
            })
        }

        if (item.so_items?.length) {
            item.so_items.sort((a, b) => {
                const a_partnerName = getPartnerName(a.order.partner_id)
                const b_partnerName = getPartnerName(b.order.partner_id)
                if (a_partnerName === b_partnerName) {
                    return b.order.warehouse.priority - a.order.warehouse.priority
                } else {
                    return a_partnerName.localeCompare(b_partnerName)
                }
            })
            for (const soi_item of item.so_items) {
                if (soi_item.order.is_cancelled) {
                    continue
                }

                if (!soi_item.qty) {
                    continue
                }

                let color: any = "dark-info"
                const suffix: string[] = []

                // TODO: ez még csak folyamatban lévő lemondás, valójában nincs lemondva
                if (soi_item.qty < soi_item.qty_ordered) {
                    color = "dark-warn"
                    suffix.push(` / ${soi_item.qty_ordered - soi_item.qty} db lemondás folyamatban`)
                }

                if (soi_item.qty_received) {
                    if (soi_item.qty_received === soi_item.qty) {
                        color = "confirm"
                    }
                    suffix.push(` / ${soi_item.qty_received} db megérkezett`)
                }

                if (soi_item.qty > soi_item.qty_ordered || soi_item.order.error) {
                    color = "critical"
                }

                const partnerName = getPartnerName(soi_item.order.partner_id)
                let target = partnerName
                if (soi_item.order.warehouse.name) {
                    target += ` — ${soi_item.order.warehouse.name}`
                }

                const targetCurrency = { currency: this.order.currency.value, rate: this.order.conversion_rate }
                const supplyPrice = convertPrice(soi_item.unit_price, targetCurrency)
                const itemUnitPrice = convertPrice(item.unit_price, targetCurrency)
                const margin = marginOf(itemUnitPrice, supplyPrice)

                let pricePrefix = `(${margin}%) `
                const totalQty = soi_item.qty_ordered
                if (totalQty > 1) {
                    pricePrefix += `${totalQty} x `
                }

                result.push({
                    color: color,
                    content: `${soi_item.qty_ordered} db beküldve (${target})${suffix.join("")}`,
                    price:
                        totalQty > 0
                            ? {
                                  prefix: pricePrefix,
                                  amount: supplyPrice.gross,
                                  currency: this.order.currency.value
                              }
                            : null,
                    action: {
                        type: "update-qty",
                        params: {
                            type: "received",
                            qty: soi_item.qty_ordered,
                            soItemId: soi_item.id
                        }
                    }
                })
            }
        }

        if (item.qty_refunded) {
            result.push({
                color: "blue",
                content: `${item.qty_refunded} db visszatérítve`
            })
        }

        if (__ENV__ === "development") {
            if (item.qty_shipped) {
                result.push({
                    color: "dark-info",
                    content: `${item.qty_shipped} db szállítva`
                })
            }

            if (item.qty_invoiced) {
                result.push({
                    color: "dark-info",
                    content: `${item.qty_invoiced} db számlázva`
                })
            }
        }

        if (this.order.status.value === "DRAFT") {
            const supplierIds = item.supplier_ids || []
            const supplierNames = supplierIds.map(getPartnerName)
            const label = item.supply_type.value === "PREORDER" ? "előrendelés" : "várakozik"

            result.push({
                color: supplierIds.length === 0 ? "vibrant" : item.supply_type.value === "PREORDER" ? "info" : "common",
                // eslint-disable-next-line max-len
                content: `${item.qty_ordered - item.qty_cancelled} db ${label} (${supplierNames.length > 0 ? supplierNames.join(",") : "Nincs forgalmazó"})`,
                action: {
                    type: "update-supplier",
                    params: {
                        type: item.supply_type.value === "PREORDER" ? "preorder" : "backorder",
                        multi: true,
                        supplierIds: supplierIds,
                        orderId: this.order.id,
                        orderItemId: item.id,
                        productId: item.product_id,
                        itemUnitPrice: item.unit_price,
                        conversionRate: this.order.conversion_rate
                    }
                }
            })
        }

        const resvs = item.reservations
        if (resvs && resvs.length) {
            for (const resv of resvs) {
                if (resv.is_processed) {
                    continue
                }

                let whName = getPartnerName(resv.warehouse.partner_id)
                if (resv.warehouse.name) {
                    whName += ` — ${resv.warehouse.name}`
                }

                result.push({
                    color: "common",
                    content: `${resv.qty} db lefoglalva (${whName})`
                })
            }
        }

        if (item.qty_backorder > 0) {
            if (item.supply_type.value === "PREORDER") {
                const preorderPartners =
                    item.supplier_ids && item.supplier_ids.length
                        ? item.supplier_ids.map(getPartnerName).join(", ")
                        : null

                result.push({
                    color: preorderPartners ? "info" : "vibrant",
                    content: `${item.qty_backorder} db előrendelve (${preorderPartners || "Nincs forgalmazó"})`,
                    action: {
                        type: "update-supplier",
                        params: {
                            type: "preorder",
                            multi: true,
                            supplierIds: item.supplier_ids,
                            orderId: this.order.id,
                            orderItemId: item.id,
                            productId: item.product_id,
                            itemUnitPrice: item.unit_price,
                            conversionRate: this.order.conversion_rate
                        }
                    }
                })
            } else if (item.supply_type.value === "BACKORDER") {
                const backorderPartners =
                    item.supplier_ids && item.supplier_ids.length
                        ? item.supplier_ids.map(getPartnerName).join(", ")
                        : null
                result.push({
                    color: backorderPartners ? "dark-warn" : "vibrant",
                    content: `${item.qty_backorder} db készlethiány (${backorderPartners || "Nincs forgalmazó"})`,
                    action: {
                        type: "update-supplier",
                        params: {
                            type: "backorder",
                            multi: true,
                            supplierIds: item.supplier_ids,
                            orderId: this.order.id,
                            orderItemId: item.id,
                            productId: item.product_id,
                            itemUnitPrice: item.unit_price,
                            conversionRate: this.order.conversion_rate
                        }
                    }
                })
            }
        }

        // TODO: COMMENT
        // if (item.supply_error) {
        //     result.push({
        //         color: "critical",
        //         content: "hiba",
        //         action: {
        //             type: "supply-error",
        //             params: {
        //                 message: item.supply_error
        //             }
        //         }
        //     })
        // }

        return result
    }
}

@Component({
    selector: ".eur-order-item[extraItem]",
    templateUrl: "./item.component.pug"
})
export class OrderExtraItemComponent extends OrderItemBase<OrderExtraItem> implements OnChanges {
    @Input() public extraItem: OrderExtraItem

    ngOnChanges(changes: SimpleChanges): void {
        if ("extraItem" in changes) {
            const item = changes.extraItem.currentValue as OrderExtraItem
            this.name = item.name
            this._orderItem.next(item)
        }
    }

    protected _determineSupplyInfos(item: OrderExtraItem, partners: Partner[]): SupplyInfo[] {
        const result: SupplyInfo[] = []

        result.push({
            content: `${item.qty_ordered} db megrendelve`,
            color: "dark-common",
            price: {
                amount: item.total_price.gross,
                currency: item.total_price.currency
            }
        })

        if (item.qty_cancelled > 0) {
            result.push({
                content: `${item.qty_cancelled} db lemondva`,
                color: "dark-warn"
            })
        }

        return result
    }
}
